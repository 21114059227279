<template>
  <div>
    <AlertWraper
      :content="content"
      :type="content_type"
    />

    <form class="default-form" @submit.prevent="submit">
      <div class="col-12 col-lg-8 mx-auto">
        <div class="">
          <label class="form-label">Motivo da baixa</label>
          <textarea v-model="form.manual_payment_description" class="form-control" rows="2"></textarea>
        </div>
      </div>
      <div class="text-center mt-5">
        <Button
          class="btn btn-primary me-4"
          @click="submit"
          :loading="loader"
        >
          Marcar como pago
        </Button>
        <Button
          class="btn btn-danger"
          @click="cancel"
        >
          Voltar
        </Button>
      </div>
    </form>
  </div>
</template>

<script>

import OrdersService from '@/modules/orders/services/orders-service'
import { parseErrorResponseToArray } from '@/utils'

import AlertWraper from '@/components/common/Alert/AlertWraper'
import Button from '@/components/common/Button/Button'

export default {
  name: 'MarkOrderAsPaidForm',
  props: ['order'],
  components: {
    AlertWraper,
    Button
  },
  data () {
    return {
      content: null,
      content_type: 'error',
      form: {
        manual_payment_description: ''
      },
      loader: false
    }
  },
  created () {
  },
  computed: {
  },
  methods: {
    /**
     * Cancel
    */
    cancel () {
      this.reset()
      this.$emit('canceled')
    },
    /**
     * Reset
    */
    reset () {
      this.form.manual_payment_description = ''
    },
    /**
     * Submit form
    */
    async submit () {
      this.loader = true
      try {
        await OrdersService.markAsPaid(this.order.id, this.form)
        this.$emit('updated')
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }
      this.loader = false
    }
  }
}
</script>
