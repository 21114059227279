<template>
  <div>
    <AlertWraper
      :content="content"
      :type="content_type"
    />

    <form class="default-form" @submit.prevent="submit">
      <h3 class="mb-4">Dados de pagamento</h3>
      <div class="d-flex mb-5">
        <div class="col-12 col-lg-4 me-4">
          <label class="required form-label">Método de pagamento</label>
          <select
            class="form-select"
            v-model.trim="form.payment_method"
            :disabled="invoice.payment_status === 'PAID'"
          >
            <option value="BOLETO">Boleto</option>
            <option value="DEPOSIT">Depósito</option>
          </select>
        </div>
        <div class="col-12 col-lg-3">
          <label class="required form-label">Prazo de pagamento</label>
          <DateTimeInput
            v-model.trim="form.payment_expiration_date"
            format="dd/MM/yyyy"
            type="date"
            theme="basic"
            class="w-100"
            :min="true"
            :disabled="invoice.payment_status === 'PAID'"
          />
        </div>
      </div>
      <template v-if="invoice.should_issue_nfse">
        <h3 class="mb-4">Dados da nota fiscal</h3>
        <div class="d-flex mb-5">
          <div class="col-12 col-lg-3 me-4">
            <label class="required form-label">Data de emissão da nota</label>
            <DateTimeInput
              v-model.trim="form.nfse_issuance_date"
              format="dd/MM/yyyy"
              type="date"
              theme="basic"
              class="w-100"
              :min="true"
              :disabled="invoice.nfse_status === 'ISSUED'"
            />
          </div>
          <div class="col-12 col-lg-6">
            <div class="">
              <label class="form-label">Descrição da NF</label>
              <textarea v-model="form.nfse_description" class="form-control" rows="2" :disabled="invoice.nfse_status === 'ISSUED'"></textarea>
            </div>
          </div>
        </div>
      </template>
      <div class="text-center">
        <Button
          class="btn btn-primary me-4"
          @click="submit"
          :loading="loader"
        >
          Editar
        </Button>
        <Button
          class="btn btn-danger"
          @click="cancel"
        >
          Cancelar
        </Button>
      </div>
    </form>
  </div>
</template>

<script>

import OrdersService from '@/modules/orders/services/orders-service'
import { parseErrorResponseToArray } from '@/utils'

import AlertWraper from '@/components/common/Alert/AlertWraper'
import Button from '@/components/common/Button/Button'
import DateTimeInput from '@/components/common/Input/DateTimeInput'

export default {
  name: 'EditOrderForm',
  props: ['order'],
  components: {
    AlertWraper,
    Button,
    DateTimeInput
  },
  data () {
    return {
      content: null,
      content_type: 'error',
      form: {
        nfse_description: '',
        nfse_issuance_date: '',
        payment_expiration_date: '',
        payment_method: ''
      },
      loader: false,
      localOrder: ''
    }
  },
  created () {
    this.localOrder = this.order
    this.form.nfse_description = this.invoice.nfse_description
    this.form.nfse_issuance_date = this.invoice.nfse_issuance_date
    this.form.payment_method = this.invoice.payment_method
    if (this.invoice.payment_method === 'BOLETO') this.form.payment_expiration_date = this.invoice.boleto_expiration_date
    else if (this.invoice.payment_method === 'DEPOSIT') this.form.payment_expiration_date = this.invoice.deposit_expiration_date
  },
  computed: {
    /**
     * Get invoice
    */
    invoice () {
      return this.localOrder.invoices[0]
    }
  },
  methods: {
    /**
     * Cancel
    */
    cancel () {
      this.reset()
      this.$emit('canceled')
    },
    /**
     * Reset
    */
    reset () {
      this.form.nfse_issuance_date = ''
      this.form.payment_expiration_date = ''
      this.form.payment_method = ''
    },
    /**
     * Submit form
    */
    async submit () {
      this.loader = true
      try {
        const order = await OrdersService.updateOrder(this.localOrder.id, this.form)
        this.localOrder = order
        this.$emit('updated', order)
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }
      this.loader = false
    }
  }
}
</script>
