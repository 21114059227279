<template>
  <b-modal
    :id="`order-detail-modal-${orderId}${orderClaimId||''}`"
    :modal-class="'default-modal modal-lg'"
    header-class="justify-content-between"
    :hide-footer="true"
    @shown="shown"
    @hidden="hidden"
  >

    <template #modal-header="{ close }">
      <div class="text-center">
        <h2 class="highlighted-color1">
          <span>Detalhes de pagamento</span>
        </h2>
      </div>
      <!--begin::Close-->
      <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close" @click="close()">
          <span class="svg-icon svg-icon-1">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
            </svg>
          </span>
      </div>
      <!--end::Close-->
    </template>

    <AlertWraper
      :content="content"
      :type="content_type"
    />
    <div class="mt-4">
      <template v-if="loader">
        <div class="text-center">
          <Loader
            :size="'big'"
          />
        </div>
      </template>
      <template v-else-if="order">
        <!-- STEP DETAIL-->
        <template v-if="step === 'details'">
          <h3 class="font-size-medium">Dados gerais</h3>
          <p>
            <strong>Número interno:</strong> {{ order.identifier }}<br/>
            <strong>Anunciante:</strong> {{ order.advertiser.name }}<br/>
            <strong>Valor:</strong> {{ order.amount / 100 | formatMoney(order.advertiser.currency) }}<br/>
            <strong>Data de criação:</strong> {{ order.created_at | formatDate('DD/MM/YYYY HH:mm:ss') }}<br/>
            <strong>URL de pagamento:</strong> <a :href="order.payment_url" target="_blank">{{ order.payment_url }}</a><br/>
            <strong>Parcela:</strong> {{ `${order.installment_number}/${order.installments_number}` }}<br/>
            <strong>Status do pagamento:</strong> {{ order.payment_status | formatPaymentStatus }}<br/>
            <template v-if="invoice">
              <strong>Emite NF:</strong> {{ invoice.should_issue_nfse ? 'Sim' : 'Não' }}<br/>
              <strong>Status da NF:</strong> {{ invoice.nfse_status | formatNFSeStatus }}<br/>
              <strong>Data de emissão da NF:</strong> {{ invoice.nfse_issuance_date | formatDate('DD/MM/YYYY') }}<br/>
              <strong>Descrição da NF:</strong> {{ invoice.nfse_description }}<br/>
            </template>
            <strong>Ativo:</strong> {{ order.is_active ? 'Sim' : 'Não' }}
          </p>
          <template v-if="order.insertion_order">
            <p>
              <router-link
                :to="{ name: 'insertion.orders.show', params: { id: order.insertion_order.id }}"
                class="fw-bold fs-6"
              >
                Clique aqui para ver detalhes do PI
              </router-link>
            </p>
          </template>
          <template v-else>
            <p>
              <strong>Criado através de PI:</strong> Não
            </p>
          </template>

          <!-- NF -->
          <template v-if="invoice && invoice.nfse_status === 'ISSUED'">
            <div class="mb-5">
              <h3>Nota fiscal</h3>
              <Button
                  class="btn btn-primary me-3"
                  @click="downloadNFSe"
                  :loading="loaderNFSe"
                >
                  Visualizar Nota Fiscal
              </Button>
              <div class="my-4" v-if="nfse">
                <div class="pdf-wrapper col-md-8 mx-auto">
                  <div class="mb-3 text-center">
                    <Button
                      class="btn btn-primary"
                      @click.prevent="$refs.pdfComponent.print()"
                    >
                      Baixar
                    </Button>
                  </div>
                  <pdf ref="pdfComponent" :src="nfse"></pdf>
                </div>
              </div>
            </div>
          </template> <!-- END NF -->

          <template v-if="invoice">
            <h3 class="font-size-medium">Dados de pagamento</h3>
            <p>
              <strong>Forma de pagamento:</strong> {{ invoice.payment_method | formatPaymentMethod }}<br/>
            </p>
            <template v-if="invoice.payment_method === 'BOLETO'">
              <template v-if="this.order.invoices.length == 1">
                <p class="mb-3">
                  <strong>Código de barras:</strong> {{ invoice.boleto_barcode }}<br/>
                  <strong>Prazo de pagamento:</strong> {{ invoice.boleto_expiration_date | formatDate('DD/MM/YYYY HH:mm:ss') }}<br/>
                  <strong>Link para impressão do boleto:</strong> <a :href="invoice.boleto_url" target="_blank">{{ invoice.boleto_url }}</a>
                </p>
              </template>
              <template v-if="this.order.invoices.length > 1">
                <div>
                  <table class="table table-rounded table-striped border gy-2 gs-7 mb-5">
                    <thead>
                      <tr class="fw-bold">
                        <th>Cod barras</th>
                        <th>Link</th>
                        <th>Status</th>
                        <th>Vencimento</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(i, index) in this.order.invoices" :key="index">
                        <td>{{ i.boleto_barcode }}</td>
                        <td><a :href="invoice.boleto_url" target="_blank">{{invoice.boleto_url}}</a></td>
                        <td>{{ i.payment_status | formatPaymentStatus }}</td>
                        <td>{{ i.boleto_expiration_date | formatDate('DD/MM/YYYY HH:mm:ss') }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </template>
            </template>
            <template v-else-if="invoice.payment_method === 'PIX'">
              <p><strong>Prazo de pagamento:</strong> {{ invoice.pix_expiration_date | formatDate('DD/MM/YYYY HH:mm:ss') }}</p>
              <PIXQRCodeCard
                :code="invoice.pix_qr_code"
              />
            </template>
            <template v-else-if="invoice.payment_method === 'CREDIT_CARD'">
              <p>
                <strong>Bandeira do cartão:</strong> {{ invoice.card_brand | formatCreditCardBrand }}<br/>
                <strong>Cartão de final:</strong> {{ invoice.card_last_digits }}
              </p>
            </template>
            <template v-else-if="invoice.payment_method === 'DEPOSIT'">
              <p><strong>Prazo de pagamento:</strong> {{ invoice.deposit_expiration_date | formatDate('DD/MM/YYYY HH:mm:ss') }}</p>
            </template>
            <template v-if="lastInvoice.payment_logs.length > 0">
              <h5 class="font-size-regular mb-3">Histórico de pagamento</h5>
              <table class="table table-rounded table-striped border gy-2 gs-7 mb-5">
                <thead>
                  <tr class="fw-bold">
                    <th>Status</th>
                    <th>Data de cobrança</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(pl, index) in lastInvoice.payment_logs" :key="index">
                    <td>{{ pl.status | formatPaymentStatus }}</td>
                    <td>{{ pl.created_at | formatDate('DD/MM/YYYY HH:mm:ss') }}</td>
                  </tr>
                </tbody>
              </table>
            </template>
            <template v-if="invoiceLogs.length > 0">
              <h5 class="font-size-regular mb-3">Histórico de log do pagamento</h5>
              <table class="table table-rounded table-striped border gy-2 gs-7 mb-5">
                <thead>
                  <tr class="fw-bold">
                    <th>Ação</th>
                    <th>Usuário</th>
                    <th>Data</th>
                  </tr>
                </thead>
                <tbody>
                  <tr  v-for="(log, index) in invoiceLogs" :key="index">
                    <td>Manualmente marcado como pago</td>
                    <td>{{ log.user_name }}</td>
                    <td>{{ log.created_at | formatDate('DD/MM/YYYY HH:mm:ss') }}</td>
                  </tr>
                </tbody>
              </table>
            </template>
          </template>
          <template v-if="order.order_claims.data.length > 0">
            <h3 class="font-size-medium">Reivindicações</h3>
            <ul>
              <li
                v-for="(order_claim, index) in order.order_claims.data"
                :key="index"
              >
                {{ order_claim.claimed_user.name  }} ({{ order_claim.responsible_profile }}) - <strong>{{ order_claim.status | formatClaimStatus }}</strong>
              </li>
            </ul>
          </template>
          <div class="mt-5 text-center">
            <Button
                class="btn btn-primary me-3"
                @click="changeStep('edit')"
              >
                Editar pagamento
            </Button>
            <Button
                class="btn btn-success me-3"
                @click="changeStep('mark-as-paid')"
                v-if="invoice && invoice.payment_status !== 'PAID'"
              >
                Marcar como pago
            </Button>
            <Button
                class="btn btn-warning btn-color-dark"
                @click="changeStep('issue-nfse')"
                :disabled="invoice && invoice.nfse_status == 'PENDING'"
                v-if="invoice && invoice.nfse_status !== 'ISSUED'"
              >
                Emitir Nota Fiscal
            </Button>
            <Button
                class="btn btn-danger"
                @click="changeStep('cancel-nfse')"
                v-if="invoice && invoice.nfse_status === 'ISSUED'"
              >
                Cancelar Nota Fiscal
            </Button>
          </div>
        </template> <!-- END STEP DETAILS-->
        <!-- STEP EDIT -->
        <template v-else-if="step === 'edit'">
          <EditOrderForm
            :order="order"
            @canceled="changeStep('details')"
            @updated="reset"
          />
        </template> <!-- END STEP EDIT -->
        <!-- STEP ISSUE NFSE -->
        <template v-else-if="step === 'issue-nfse'">
          <p class="text-center">
            Você deseja realizar a emissão da nota?
          </p>
          <div class="text-center mt-5">
            <Button
                class="btn btn-primary me-3"
                @click="issueNFSe"
                :loading="loaderNFSe"
              >
                Emitir Nota Fiscal
            </Button>
            <Button
                class="btn btn-danger"
                @click="changeStep('details')"
              >
                Voltar
            </Button>
          </div>
        </template> <!-- END STEP ISSUE NFSE -->
        <!-- STEP CANCEL NFSE -->
        <template v-else-if="step === 'cancel-nfse'">
          <p class="text-center">
            Você tem certeza que deseja cancelar a nota? Essa ação é <strong>irreversível</strong>.
          </p>
          <div class="text-center mt-5">
            <Button
                class="btn btn-primary me-3"
                @click="cancelNFSe"
                :loading="loaderNFSe"
              >
                Cancelar Nota Fiscal
            </Button>
            <Button
                class="btn btn-danger"
                @click="changeStep('details')"
              >
                Voltar
            </Button>
          </div>
        </template> <!-- END STEP CANCEL NFSE -->
        <!-- STEP MARK AS PAID -->
        <template v-else-if="step === 'mark-as-paid'">
          <p class="text-center">
            Você tem certeza que deseja marcar como <strong>pago</strong>? Essa ação é <strong>irreversível</strong>.
          </p>
          <MarkOrderAsPaidForm
            :order="order"
            @canceled="changeStep('details')"
            @updated="reset"
          />
        </template>
      </template>
    </div>
  </b-modal>
</template>

<script>

import LogsService from '@/modules/logs/services/logs-service'
import OrdersService from '@/modules/orders/services/orders-service'
import { parseErrorResponseToArray } from '@/utils'

import AlertWraper from '@/components/common/Alert/AlertWraper'
import Button from '@/components/common/Button/Button'
import EditOrderForm from '@/components/orders/EditOrderForm'
import Loader from '@/components/common/Loader/Loader'
import MarkOrderAsPaidForm from '@/components/orders/MarkOrderAsPaidForm'
import pdf from 'vue-pdf'

export default {
  name: 'OrderDetailModal',
  props: ['orderId', 'orderClaimId'],
  components: {
    AlertWraper,
    Button,
    EditOrderForm,
    MarkOrderAsPaidForm,
    Loader,
    pdf
  },
  data () {
    return {
      content: null,
      content_type: 'error',
      invoiceLogs: [],
      loader: false,
      loaderButton: false,
      loaderMarkAsPaid: false,
      loaderNFSe: false,
      nfse: null,
      order: null,
      payment_expiration_date: '',
      step: 'details'
    }
  },
  computed: {
    invoice () {
      return this.order && this.order.invoices.length > 0 ? this.order.invoices[0] : null
    },
    lastInvoice () {
      const invoiceCount = this.order.invoices.length
      return this.order.invoices[invoiceCount - 1]
    }
  },
  methods: {
    /**
     * Cancel NFSe
    */
    async cancelNFSe () {
      this.loaderNFSe = true
      try {
        await OrdersService.cancelNFSe(this.order.id)
        this.reset()
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }
      this.loaderNFSe = false
    },
    /**
     * Change step
    */
    changeStep (step) {
      this.step = step
    },
    /**
     * Download nfse
     */
    async downloadNFSe () {
      this.loaderNFSe = true
      try {
        const data = await OrdersService.downloadNFSe(this.order.id)
        var raw = window.atob(data)
        var rawLength = raw.length
        var array = new Uint8Array(new ArrayBuffer(rawLength))

        for (var i = 0; i < rawLength; i++) {
          array[i] = raw.charCodeAt(i)
        }
        this.nfse = array
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }
      this.loaderNFSe = false
    },
    /**
     * On modal hidden
     */
    hidden () {
      this.order = null
      this.content = null
      this.nfse = null
      this.step = 'details'
    },
    /**
     * Get invoice logs
    */
    async getInvoiceLogs () {
      if (!this.invoice) return
      const logs = await LogsService.getActivityLogs({
        type: 'INVOICE_PAID_ADMIN',
        loggable_type: 'INVOICE',
        loggable_id: this.invoice.id
      })
      this.invoiceLogs = logs
    },
    /**
     * Get order
     */
    async getOrder () {
      this.loader = true
      try {
        const order = await OrdersService.getOrder(this.orderId)
        this.order = order
        this.getInvoiceLogs()
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }
      this.loader = false
    },
    /**
     * Issue NFSe
    */
    async issueNFSe () {
      this.loaderNFSe = true
      try {
        await OrdersService.issueNFSe(this.order.id)
        this.reset()
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }
      this.loaderNFSe = false
    },
    /**
     * Mark as paid
    */
    async markAsPaid () {
      this.loaderMarkAsPaid = true
      try {
        await OrdersService.markAsPaid(this.order.id)
        this.reset()
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }
      this.loaderMarkAsPaid = false
    },
    /**
     * On modal shown
    */
    shown () {
      this.getOrder()
      this.step = 'details'
    },
    /**
     * Reset
    */
    reset () {
      this.order = ''
      this.changeStep('details')
      this.getOrder()
    }
  }
}
</script>
